<template>
  <div>
    <section>
      <b-form
        id="address-setup-form"
        name="address-setup-form"
        autocomplete="off"
        @submit.prevent="onSubmitAddressSetup"
        novalidate
      >
        <b-form-row
          v-for="(config, index) in addressConfigurations.addressConfigs"
          :key="index"
        >
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">{{ config.title }}</h4>
              <div class="floating-input-field">
                <b-form-input
                  :id="'address-configuration-' + index"
                  type="url"
                  placeholder=" "
                  v-model="config.value"
                  required
                ></b-form-input>
                <label :for="'address-configuration-' + index">{{
                  config.title
                }}</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!config.value && formSubmitted"
                  >{{ config.title }} required.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    config.value && !validateUrl(config.value) && formSubmitted
                  "
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-if="addressConfigurations.isCosmosDB">
          <b-col cols="12" sm="7" md="6" lg="4">
            <b-form-group>
              <b-form-checkbox
                v-model="addressConfigurations.isCosmosDB.value"
                name="cosmo-db-checkbox"
              >
                <h4 class="mt-1 page-sub-header">
                  {{ addressConfigurations.isCosmosDB.title }}
                </h4>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
        <template
          v-if="
            addressConfigurations.isCosmosDB &&
            addressConfigurations.isCosmosDB.value
          "
        >
          <b-form-row
            v-for="(
              cosmoDBConfig, cIndex
            ) in addressConfigurations.cosmosDbAddressConfigs"
            :key="cosmoDBConfig.id"
          >
            <b-col cols="12" sm="10" md="8" lg="6">
              <b-form-group>
                <h4 class="page-sub-header">{{ cosmoDBConfig.title }}</h4>
                <div class="floating-input-field">
                  <b-form-input
                    :id="'cosmo-db-configuration-' + cIndex"
                    type="url"
                    placeholder=" "
                    v-model="cosmoDBConfig.value"
                    required
                  ></b-form-input>
                  <label :for="'cosmo-db-configuration-' + cIndex">{{
                    cosmoDBConfig.title
                  }}</label>
                  <b-form-invalid-feedback
                    class="d-block"
                    v-if="!cosmoDBConfig.value && formSubmitted"
                    >{{
                      cosmoDBConfig.title
                    }}
                    required.</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
        </template>
        <b-form-row class="my-3">
          <b-col>
            <b-button
              type="submit"
              v-activeBlur
              variant="primary"
              :disabled="saveLoadingIcon"
              >Save
              <b-spinner
                v-if="saveLoadingIcon"
                label="Spinning"
                small
                class="ml-2"
              ></b-spinner
            ></b-button>
            <b-button
              type="button"
              v-activeBlur
              class="ml-3"
              @click="resetAddressSetupForm"
              variant="outline-secondary"
              >Cancel</b-button
            >
          </b-col>
        </b-form-row>
      </b-form>
    </section>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
import { useValidateFields } from '@/composables/useValidateFields'
export default {
  name: 'AddressSetup',
  setup() {
    const { validateUrl, scrollToErrorMessage } = useValidateFields()

    return { validateUrl, scrollToErrorMessage }
  },
  data () {
    return {
      formSubmitted: false,
      saveLoadingIcon: false,
      cloneAddressConfigurations: [],
      addressConfigurations: []
    }
  },
  mounted () {
    this.getAddressConfigurations()
  },
  methods: {
    showToaster (message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type
      })
    },
    getAddressConfigurations () {
      this.$store
        .dispatch(
          'cities/getAddressConfigurations',
          this.$route.params.tenantID
        )
        .then((response) => {
          this.addressConfigurations = cloneDeep(response.data)
          this.cloneAddressConfigurations = cloneDeep(response.data)
        })
        .catch(() => {
          this.resetAddressSetupForm()
          this.showToaster(false, 'ERROR_GET_ADDRESS_SETUP', 'danger')
        })
    },
    resetAddressSetupForm () {
      this.addressConfigurations = cloneDeep(this.cloneAddressConfigurations)
    },
    validateAddressSetupForm () {
      let isCosmoDBValueError = false
      const validUrl = !this.addressConfigurations.addressConfigs.some(
        (config) => !this.validateUrl(config.value)
      )
      if (
        this.addressConfigurations.isCosmosDB &&
        this.addressConfigurations.isCosmosDB.value
      ) {
        isCosmoDBValueError =
          this.addressConfigurations.cosmosDbAddressConfigs.some(
            (config) => !config.value
          )
      }
      this.scrollToErrorMessage()
      return !isCosmoDBValueError && validUrl
    },
    onSubmitAddressSetup () {
      this.formSubmitted = true
      if (this.validateAddressSetupForm()) {
        if (
          this.addressConfigurations.isCosmosDB &&
          !this.addressConfigurations.isCosmosDB.value
        ) {
          this.addressConfigurations.cosmosDbAddressConfigs.forEach(
            (data) => (data.value = '')
          )
        }
        this.saveLoadingIcon = true
        this.$store
          .dispatch(
            'cities/updateAddressConfigurations',
            this.addressConfigurations
          )
          .then((response) => {
            this.saveLoadingIcon = false
            if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
              this.showToaster(response.data.errorMessage, false, 'danger')
            } else if (response.data) {
              this.getAddressConfigurations()
              this.$store.dispatch('common/getAdminTenants')
              this.formSubmitted = false
              this.showToaster(false, 'UPDATE_ADDRESS_SETUP', 'success')
            } else {
              this.showToaster(false, 'FAILED', 'danger')
            }
          })
          .catch(() => {
            this.saveLoadingIcon = false
            this.showToaster(false, 'FAILED', 'danger')
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>

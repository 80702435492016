import { render, staticRenderFns } from "./AddressSetup.vue?vue&type=template&id=51620c03&scoped=true&"
import script from "./AddressSetup.vue?vue&type=script&lang=js&"
export * from "./AddressSetup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51620c03",
  null
  
)

export default component.exports